













































































































































// Base
import Vue from "vue";
// import { defineComponent } from "vue";

// Types
import { SiteOptions, BannerAd } from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyItem = {
    title: "",
    url: "",
    altText: "",
    image: "",
    slots: []
} as BannerAd;

export default Vue.extend({
    name: "AdminOptionBannerAds",
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            updatePayload: {
                bannerAds: []
            } as SiteOptions
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            this.updatePayload.bannerAds?.splice(0);
            await this.$store.dispatch("getOptions", ["bannerAds"]);
            this.options.bannerAds?.forEach((item: BannerAd) => {
                this.updatePayload.bannerAds?.push(item);
            });
            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("updateOptions", this.updatePayload);
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        },
        addItem(): void {
            const newItem = {
                ...emptyItem
            };

            this.updatePayload.bannerAds?.push(newItem);
        },
        deleteItemDialog(index: number): void {
            this.$buefy.dialog.confirm({
                title: "Delete Item",
                message: "Are you sure you want to delete this item?",
                confirmText: "Delete",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.deleteItem(index);
                }
            });
        },
        deleteItem(index: number): void {
            this.updatePayload.bannerAds?.splice(index, 1);
        }
    }
});
