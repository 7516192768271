



























































































































































// Base
import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

// Types
import { SiteOptions, SiteNavigationItem } from "@/types/interfaces";

const validPages = [
    {
        path: "/home",
        name: "Home",
        displayName: "Home"
    },
    {
        path: "/attendees",
        name: "Attendees",
        displayName: "Attendees"
    },
    {
        path: "/sessions",
        name: "Sessions",
        displayName: "Schedule"
    },
    {
        path: "/tradeshow",
        name: "Tradeshow",
        displayName: "Tradeshow"
    },
    {
        path: "/presenters",
        name: "Presenters",
        displayName: "Presenters"
    },
    {
        path: "/message-center",
        name: "Message-Center",
        displayName: "Message Center"
    },
    {
        path: "/schedule",
        name: "Schedule",
        displayName: "My Schedule"
    },
    {
        path: "/posters",
        name: "Posters",
        displayName: "Posters"
    },
    {
        path: "/QOTD",
        name: "QOTD",
        displayName: "? of the Day"
    },
    {
        name: "AdditionalInfo",
        path: "/additional-info",
        displayName: "Additional Info"
    },
    {
        name: "Help",
        path: "/help",
        displayName: "Help"
    },
    {
        name: "External Link",
        path: "http://",
        displayName: "External Link"
    }
] as Array<Partial<SiteNavigationItem>>;

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyItem = {
    path: "",
    name: "",
    displayName: "New Item",
    active: true,
    order: 0
} as SiteNavigationItem;

export default Vue.extend({
    name: "AdminOptionActiveNavigation",
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            updatePayload: {
                activeNavigation: [] as Array<SiteNavigationItem>
            } as SiteOptions,
            validPages: validPages,
            selectedPagePath: "",
            addModalOpen: false
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("getOptions", ["activeNavigation"]);
            this.updatePayload.activeNavigation?.splice(0);
            this.options.activeNavigation?.forEach(
                (item: SiteNavigationItem) => {
                    this.updatePayload.activeNavigation?.push(item);
                }
            );
            this.updatePayload.activeNavigation?.sort(
                (a: SiteNavigationItem, b: SiteNavigationItem) => {
                    return a.order - b.order;
                }
            );
            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("updateOptions", this.updatePayload);
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        },
        addItem(): void {
            const selectedItem = this.validPages.find(
                (page) => page.path === this.selectedPagePath
            );

            const newItem = {
                ...emptyItem,
                ...selectedItem
            };

            if (
                this.updatePayload.activeNavigation &&
                this.updatePayload.activeNavigation.length
            ) {
                newItem.order = this.updatePayload.activeNavigation.length;
            }

            this.updatePayload.activeNavigation?.push(newItem);

            this.closeModal();
        },
        deleteItemDialog(index: number): void {
            this.$buefy.dialog.confirm({
                title: "Delete Item",
                message: "Are you sure you want to delete this item?",
                confirmText: "Delete",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.deleteItem(index);
                }
            });
        },
        deleteItem(index: number): void {
            this.updatePayload.activeNavigation?.splice(index, 1);
        },
        closeModal() {
            this.selectedPagePath = "";
            this.addModalOpen = false;
        }
    }
});
