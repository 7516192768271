


























































// Base
import Vue from "vue";
// import { defineComponent } from "vue";

// Types
import { SiteOptions, ActionIcon } from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyItem = {
    title: "",
    imgPath: "",
    link: "",
    content: ""
} as ActionIcon;

export default Vue.extend({
    name: "AdminOptionPolls",
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            updatePayload: {
                pollSettings: {
                    showResultsInSidebar: false
                }
            } as SiteOptions
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            this.updatePayload.actionIconSettings?.icons.splice(0);
            await this.$store.dispatch("getOptions", ["pollSettings"]);

            if (
                this.options.pollSettings?.showResultsInSidebar &&
                this.updatePayload.pollSettings
            ) {
                this.updatePayload.pollSettings.showResultsInSidebar = true;
            }

            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("updateOptions", this.updatePayload);
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        }
    }
});
