



































































































































// Base
import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

// Types
import { SiteOptions, ActionIcon } from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyItem = {
    title: "",
    imgPath: "",
    link: "",
    content: ""
} as ActionIcon;

export default Vue.extend({
    name: "AdminOptionActionIcons",
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            updatePayload: {
                actionIconSettings: {
                    enabled: false,
                    header: "",
                    icons: []
                }
            } as SiteOptions
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            this.updatePayload.actionIconSettings?.icons.splice(0);
            await this.$store.dispatch("getOptions", ["actionIconSettings"]);
            if (
                this.updatePayload.actionIconSettings &&
                this.options.actionIconSettings
            ) {
                if (this.options.actionIconSettings.enabled) {
                    this.updatePayload.actionIconSettings.enabled = true;
                }

                this.options.actionIconSettings.icons.forEach(
                    (item: ActionIcon) => {
                        this.updatePayload.actionIconSettings?.icons.push(item);
                    }
                );
                this.updatePayload.actionIconSettings.header = this.options
                    .actionIconSettings.header
                    ? this.options.actionIconSettings.header
                    : "";
            }
            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("updateOptions", this.updatePayload);
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        },
        addItem(): void {
            const newItem = {
                ...emptyItem
            };

            this.updatePayload.actionIconSettings?.icons.push(newItem);
        },
        deleteItemDialog(index: number): void {
            this.$buefy.dialog.confirm({
                title: "Delete Item",
                message: "Are you sure you want to delete this item?",
                confirmText: "Delete",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.deleteItem(index);
                }
            });
        },
        deleteItem(index: number): void {
            this.updatePayload.actionIconSettings?.icons.splice(index, 1);
        }
    }
});
