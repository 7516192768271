

















































































































// Base
import Vue from "vue";
// import { defineComponent } from "vue";

// Types
import { SiteOptions, TagItem } from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyItem = {
    id: 0,
    content: "",
    tooltip: "",
    color: ""
} as TagItem;

export default Vue.extend({
    name: "AdminOptionTags",
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            updatePayload: {
                tags: []
            } as SiteOptions
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            this.updatePayload.tags?.splice(0);
            await this.$store.dispatch("getOptions", ["tags"]);
            this.options.tags?.forEach((item: TagItem) => {
                this.updatePayload.tags?.push(item);
            });
            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("updateOptions", this.updatePayload);
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        },
        addItem(): void {
            const newItem = {
                ...emptyItem
            };

            this.updatePayload.tags?.push(newItem);
        },
        deleteItemDialog(index: number): void {
            this.$buefy.dialog.confirm({
                title: "Delete Item",
                message: "Are you sure you want to delete this item?",
                confirmText: "Delete",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.deleteItem(index);
                }
            });
        },
        deleteItem(index: number): void {
            this.updatePayload.tags?.splice(index, 1);
        }
    }
});
