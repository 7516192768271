

















// Base
import Vue from "vue";
// import { defineComponent } from "vue";

// Components
import AdminOptionQotd from "./individual/AdminOptionQotd.vue";
import AdminOptionActiveNavigation from "./individual/AdminOptionActiveNavigation.vue";
import AdminOptionBannerAds from "./individual/AdminOptionBannerAds.vue";
import AdminOptionTags from "./individual/AdminOptionTags.vue";
import AdminOptionActionIcons from "./individual/AdminOptionActionIcons.vue";
import AdminOptionPolls from "./individual/AdminOptionPolls.vue";
import AdminOptionAlertMessages from "./individual/AdminOptionAlertMessages.vue";

export default Vue.extend({
    components: {
        AdminOptionQotd,
        AdminOptionActiveNavigation,
        AdminOptionBannerAds,
        AdminOptionTags,
        AdminOptionActionIcons,
        AdminOptionPolls,
        AdminOptionAlertMessages
    }
});
