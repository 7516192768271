






























































































// Base
import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";

// Types
import { SiteOptions, QuestionOfTheDay } from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyQuestion = {
    id: "",
    date: new Date(),
    text: ""
} as QuestionForEdit;

interface QuestionForEdit {
    id: string;
    date: Date;
    text: string;
}

export default Vue.extend({
    name: "AdminOptionQotd",
    components: {
        Spinners
    },
    data() {
        return {
            updatePayload: {
                questionsOfTheDay: []
            } as SiteOptions,
            loading: false
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("getOptions", ["questionsOfTheDay"]);
            this.updatePayload.questionsOfTheDay?.splice(0);
            this.options.questionsOfTheDay?.forEach(
                (question: QuestionOfTheDay) => {
                    const newQuestion = {
                        ...question,
                        date: new Date(question.date)
                    } as QuestionForEdit;
                    this.updatePayload.questionsOfTheDay?.push(newQuestion);
                }
            );
            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;
            await this.$store.dispatch("updateOptions", this.updatePayload);
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        },
        addItem(): void {
            this.updatePayload.questionsOfTheDay?.push({
                ...emptyQuestion
            });
        },
        deleteItemDialog(index: number): void {
            this.$buefy.dialog.confirm({
                title: "Delete Question",
                message: "Are you sure you want to delete this question?",
                confirmText: "Delete",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.deleteItem(index);
                }
            });
        },
        deleteItem(index: number): void {
            this.updatePayload.questionsOfTheDay?.splice(index, 1);
        }
    }
});
