
























































































































































































































// Base
import Vue from "vue";
// import { defineComponent } from "vue";
import { format } from "date-fns";

// Types
import {
    SiteOptions,
    AlertMessage,
    AlertMessageEditable
} from "@/types/interfaces";

// Components
import Spinners from "@/components/utilities/Spinners.vue";

const emptyItem = {
    message: "",
    startDate: null,
    endDate: null,
    actionUrl: "",
    actionLabel: "",
    id: "",
    canClose: true,
    alertType: "is-info",
    icon: ""
} as AlertMessageEditable;

interface AlertType {
    pretty: string;
    name: string;
    icon: string;
}

const alertTypes = [
    {
        pretty: "Info (light blue)",
        name: "is-info",
        icon: "info-circle"
    },
    {
        pretty: "Success (green)",
        name: "is-success",
        icon: "check-circle"
    },
    {
        pretty: "Warning (yellow)",
        name: "is-warning",
        icon: "exclamation-triangle"
    },
    {
        pretty: "Danger (red)",
        name: "is-danger",
        icon: "exclamation-circle"
    }
] as Array<AlertType>;

export default Vue.extend({
    name: "AdminOptionAlertMessages",
    components: {
        Spinners
    },
    data() {
        return {
            loading: false,
            updatePayload: {
                alertMessages: []
            } as SiteOptions,
            alertTypes
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        }
    },
    methods: {
        async getData(): Promise<void> {
            this.loading = true;
            this.updatePayload.alertMessages?.splice(0);
            await this.$store.dispatch("getOptions", ["alertMessages"]);
            this.options.alertMessages?.forEach(
                (item: AlertMessage | AlertMessageEditable) => {
                    const editable = {
                        ...item
                    } as AlertMessageEditable;

                    // Dates come from the DB in string form, so make them dates to work with date select component.
                    editable.startDate = item.startDate
                        ? new Date(item.startDate)
                        : null;
                    editable.endDate = item.endDate
                        ? new Date(item.endDate)
                        : null;

                    this.updatePayload.alertMessages?.push(editable);
                }
            );
            this.loading = false;
        },
        async saveData(): Promise<void> {
            this.loading = true;

            // Alerts need to save to the DB in string form, so convert them.
            const newAlerts = this.updatePayload.alertMessages
                ? [...this.updatePayload.alertMessages]
                : [];
            newAlerts.forEach((item) => {
                // If these are dates, convert them to strings. Otherwise, leave them alone (probably null).
                item.startDate =
                    item.startDate && item.startDate instanceof Date
                        ? item.startDate.toISOString()
                        : item.startDate;
                item.endDate =
                    item.endDate && item.endDate instanceof Date
                        ? item.endDate.toISOString()
                        : item.endDate;
            });
            await this.$store.dispatch("updateOptions", {
                alertMessages: newAlerts
            });
            await this.getData();
        },
        async resetData(): Promise<void> {
            await this.getData();
        },
        resetDialog(): void {
            this.$buefy.dialog.confirm({
                message: "Are you sure you want to reset this data?",
                confirmText: "Reset",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.resetData();
                }
            });
        },
        addItem(): void {
            const newItem = {
                ...emptyItem
            };

            this.updatePayload.alertMessages?.push(newItem);
        },
        deleteItemDialog(index: number): void {
            this.$buefy.dialog.confirm({
                title: "Delete Item",
                message: "Are you sure you want to delete this item?",
                confirmText: "Delete",
                type: "is-danger",
                hasIcon: true,
                onConfirm: () => {
                    this.deleteItem(index);
                }
            });
        },
        deleteItem(index: number): void {
            this.updatePayload.alertMessages?.splice(index, 1);
        },
        setTimeToNow(index: number): void {
            if (this.updatePayload.alertMessages) {
                const now = new Date();
                this.updatePayload.alertMessages[
                    index
                ].startDate = now.toISOString();
            }
        },
        displayedItemTitle(item: AlertMessage | AlertMessageEditable) {
            const startAsDate = item.startDate
                ? new Date(item.startDate)
                : false;
            return startAsDate
                ? `(${format(startAsDate, "yyyy-MM-dd h:mm a")}) ${
                      item.message
                  }`
                : "";
        },
        renderedIcon(item: AlertMessage | AlertMessageEditable) {
            const alertType = this.alertTypes.find(
                (singleType) => singleType.name === item.alertType
            );
            return item.icon ? item.icon : alertType ? alertType.icon : "";
        }
    }
});
